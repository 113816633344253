import Button from "components/Buttons/Button/Button";
import icons from "helpers/icons";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AddToCartButton from "../../../components/Form/AddToCartButton/AddToCartButton";
import PlusMinusInput from "../../../components/Form/PlusMinusInput/PlusMinusInput";
import __ from "../../../config/translations/serbian";
import { currencyFormat } from "../../../helpers/functions";
import useAPI from "../../../hooks/useAPI";
import classes from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import styles from "../../Checkout/CheckoutTable/CheckoutTable.module.scss";
import scss from "../../Catalogue/Products/ProductItems/ProductListItem/ProductListItem.module.scss";
import FormWrapper from "components/Form/FormWrapper";

const Footer = ({ product, inWishlist, productVariant }) => {
    const api = useAPI();
    const navigate = useNavigate();
    // Track the quantity to add to the cart
    const [quantity, setQuantity] = useState(1);
    const getQuantity = () => {
        if (quantity === 0) {
            setQuantity(1);
            return 1;
        }

        return quantity;
    };

    // FIXME
    const showRebate = (product) => () => {
        product?.price?.rebate && toast.success(`(${product.price.rebate.id}) "${product.price.rebate.name}" ➡  ${product.price.rebate.amount}%`);
    };
    const [loading, setLoading] = useState(false);
    const [isPlusMinusInputEmpty, setIsPlusMinusInputEmpty] = useState(false);

    const renderPrices = (item) => {
        let priceOpt = {
            status: null,
            display_original: false,
            original: null,
            display_discount: false,
            discount: null,
        };

        switch (item.product_type) {
            case "variant":
                switch (item?.price?.discount?.active || item?.price?.rebate?.active) {
                    case true:
                        switch (item?.price?.min?.price?.original === item?.price?.max?.price?.original) {
                            case true:
                                return (
                                    <>
                                        <Tooltip
                                            placement={"top"}
                                            arrow={true}
                                            title={
                                                <Box>
                                                    <Box>
                                                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                                    </Box>

                                                    {item?.price?.rebate?.active &&
                                                        item?.price?.rebate?.rebates?.map((rebate) => {
                                                            return (
                                                                <Box key={item.id}>
                                                                    Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                                </Box>
                                                            );
                                                        })}

                                                    {item?.price?.discount?.active &&
                                                        item?.price?.discount?.campaigns?.map((campaign) => {
                                                            return (
                                                                <Box key={item.id}>
                                                                    Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                                </Box>
                                                            );
                                                        })}
                                                </Box>
                                            }
                                        >
                                            <span
                                                className={`${scss.price} text-[1rem] self-start font-normal py-2 ${item?.price?.discount?.active ? scss.discounted : ""} ${
                                                    item?.price?.rebate?.active ? scss.rebateStyle : ""
                                                }  ${item?.price?.discount?.active && item?.price?.rebate?.active ? scss.discountRabate : ""}`}
                                            >
                                                {item?.price?.price?.discount > 0 ? currencyFormat(item?.price?.price?.discount) : "Cena na upit"}{" "}
                                            </span>
                                        </Tooltip>
                                    </>
                                );
                                break;
                            case false:
                                return (
                                    <>
                                        <Tooltip
                                            placement={"top"}
                                            arrow={true}
                                            title={
                                                <Box>
                                                    <Box>
                                                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                                    </Box>

                                                    {item?.price?.rebate?.active &&
                                                        item?.price?.rebate?.rebates?.map((rebate) => {
                                                            return (
                                                                <Box key={item.id}>
                                                                    Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                                </Box>
                                                            );
                                                        })}

                                                    {item?.price?.discount?.active &&
                                                        item?.price?.discount?.campaigns?.map((campaign) => {
                                                            return (
                                                                <Box key={item.id}>
                                                                    Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                                </Box>
                                                            );
                                                        })}
                                                </Box>
                                            }
                                        >
                                            <span
                                                className={`text-[1rem] self-start ${scss.discount} font-normal py-2 ${scss.price} ${scss.tcPrice} ${
                                                    item?.price?.discount?.active ? scss.discounted : ""
                                                } ${item?.price?.rebate?.active ? scss.rebateStyle : ""}  ${item?.price?.discount?.active && item?.price?.rebate?.active ? scss.discountRabate : ""}`}
                                            >
                                                {(item?.price?.min?.price?.discount && item?.price?.max?.price?.discount) > 0 ? (
                                                    <>{currencyFormat(item?.price?.min?.price?.discount) - currencyFormat(item?.price?.max?.price?.discount)}</>
                                                ) : (
                                                    "Cena na upit"
                                                )}
                                            </span>
                                        </Tooltip>
                                    </>
                                );
                                break;
                        }
                    case false:
                        switch (item?.price?.min?.price?.original === item?.price?.max?.price?.original) {
                            case true:
                                return (
                                    <>
                                        <span>{item?.price?.min?.price?.original > 0 ? currencyFormat(item?.price?.min?.price?.original) : "Cena na upit"}</span>
                                    </>
                                );
                                break;
                            case false:
                                return (
                                    <>
                                        <span>
                                            {(item?.price?.min?.price?.original && item?.price?.max?.price?.original) > 0 ? (
                                                <>
                                                    {" "}
                                                    {currencyFormat(item?.price?.min?.price?.original)} - {currencyFormat(item?.price?.max?.price?.original)}
                                                </>
                                            ) : (
                                                "Cena na upit"
                                            )}
                                        </span>
                                    </>
                                );
                                break;
                        }
                }
                break;
            case "single":
                switch (true) {
                    case item?.price?.discount?.active && item?.price?.rebate?.active:
                        return (
                            <>
                                <Tooltip
                                    placement={"top"}
                                    arrow={true}
                                    title={
                                        <Box>
                                            <Box>
                                                Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                            </Box>

                                            {item?.price?.rebate?.active &&
                                                item?.price?.rebate?.rebates?.map((rebate) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                        </Box>
                                                    );
                                                })}

                                            {item?.price?.discount?.active &&
                                                item?.price?.discount?.campaigns?.map((campaign) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    }
                                >
                                    <span className={`text-[1rem] self-start font-normal py-2 ${scss.discountRabate}`}>
                                        {item?.price?.price?.discount > 0 ? currencyFormat(item?.price?.price?.discount) : "Cena na upit"}
                                    </span>
                                </Tooltip>
                            </>
                        );
                        break;
                    case item?.price?.discount?.active && !item?.price?.rebate?.active:
                        return (
                            <>
                                <Tooltip
                                    placement={"top"}
                                    arrow={true}
                                    title={
                                        <Box>
                                            <Box>
                                                Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                            </Box>

                                            {item?.price?.rebate?.active &&
                                                item?.price?.rebate?.rebates?.map((rebate) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                        </Box>
                                                    );
                                                })}

                                            {item?.price?.discount?.active &&
                                                item?.price?.discount?.campaigns?.map((campaign) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa popustom ({campaign?.calc?.calc_name}):{" "}
                                                            <span
                                                                style={{
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {currencyFormat(campaign?.calc?.price)}
                                                            </span>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    }
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                        className={`text-[1rem] self-start font-normal py-2 ${scss.tcPrice} ${scss.discounted}`}
                                    >
                                        {item?.price?.price?.discount > 0 ? currencyFormat(item?.price?.price?.discount) : "Cena na upit"}
                                    </span>
                                </Tooltip>
                            </>
                        );
                        break;
                    case !item?.price?.discount?.active && item?.price?.rebate?.active:
                        return (
                            <>
                                <Tooltip
                                    placement={"top"}
                                    arrow={true}
                                    title={
                                        <Box>
                                            <Box>
                                                Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                            </Box>

                                            {item?.price?.rebate?.active &&
                                                item?.price?.rebate?.rebates?.map((rebate) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                        </Box>
                                                    );
                                                })}

                                            {item?.price?.discount?.active &&
                                                item?.price?.discount?.campaigns?.map((campaign) => {
                                                    return (
                                                        <Box key={item.id}>
                                                            Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                        </Box>
                                                    );
                                                })}
                                        </Box>
                                    }
                                >
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                        className={`text-[1rem] self-start font-normal py-2 ${scss.rebateStyle}`}
                                    >
                                        {item?.price?.price?.discount > 0 ? currencyFormat(item?.price?.price?.discount) : "Cena na upit"}
                                    </span>
                                </Tooltip>
                            </>
                        );
                        break;
                    default:
                        return (
                            <>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {item?.price?.price?.original !== 0 ? currencyFormat(item?.price?.price?.original) : "-"}
                                </span>
                            </>
                        );
                        break;
                }
        }

        switch (priceOpt.status) {
            case "rebate_discount":
                return (
                    <>
                        <Tooltip
                            placement={"top"}
                            arrow={true}
                            title={
                                <Box>
                                    <Box>
                                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                    </Box>

                                    {item?.price?.rebate?.active &&
                                        item?.price?.rebate?.rebates?.map((rebate) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}

                                    {item?.price?.discount?.active &&
                                        item?.price?.discount?.campaigns?.map((campaign) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            }
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                }}
                                className={`text-[0.875rem] self-start text-black font-semibold py-1 font-bold ${scss.line} `}
                            >
                                {priceOpt.display_original ? priceOpt.original : "-"}
                            </p>
                            <div className="bg-croonus-3 self-start w-100 ">
                                <span className={`text-[1rem] self-start text-white font-normal py-2 ${scss.price} ${scss.discountedUnderline} `}>
                                    {priceOpt.display_discount ? priceOpt.discount : "-"}
                                </span>
                            </div>
                        </Tooltip>
                    </>
                );
            case "discount":
                return (
                    <>
                        <Tooltip
                            placement={"top"}
                            arrow={true}
                            title={
                                <Box>
                                    <Box>
                                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                    </Box>

                                    {item?.price?.rebate?.active &&
                                        item?.price?.rebate?.rebates?.map((rebate) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}

                                    {item?.price?.discount?.active &&
                                        item?.price?.discount?.campaigns?.map((campaign) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            }
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                }}
                                className={`text-[0.875rem] self-start text-black font-semibold py-1 ${scss.line} `}
                            >
                                {priceOpt?.display_original ? priceOpt?.original : "-"}
                            </p>
                            <div className="bg-croonus-3 self-start w-100 ">
                                <span className={`text-[1rem] self-start text-white font-normal py-2 ${scss.price} ${scss.discounted}`}>{priceOpt?.display_discount ? priceOpt?.discount : "-"}</span>
                            </div>
                        </Tooltip>
                    </>
                );
                break;
            case "rebate":
                return (
                    <>
                        <Tooltip
                            placement={"top"}
                            arrow={true}
                            title={
                                <Box>
                                    <Box>
                                        Regularna cena: <span>{currencyFormat(item?.price?.price?.original)}</span>
                                    </Box>

                                    {item?.price?.rebate?.active &&
                                        item?.price?.rebate?.rebates?.map((rebate) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa rabatom ({rebate?.calc?.calc_name}): <span>{currencyFormat(rebate?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}

                                    {item?.price?.discount?.active &&
                                        item?.price?.discount?.campaigns?.map((campaign) => {
                                            return (
                                                <Box key={item.id}>
                                                    Cena sa popustom ({campaign?.calc?.calc_name}): <span>{currencyFormat(campaign?.calc?.price)}</span>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            }
                        >
                            <p
                                style={{
                                    fontWeight: "bold",
                                }}
                                className={`text-[0.875rem] self-start text-black font-semibold py-1 ${scss.line} ${scss.rebateStyle} `}
                            >
                                {priceOpt.discount ? priceOpt.discount : priceOpt?.original ?? " - "}
                            </p>
                        </Tooltip>
                    </>
                );
                break;
            default:
                return (
                    <>
                        <p
                            style={{
                                fontWeight: "bold",
                            }}
                            className={`text-[0.875rem] self-start text-black font-semibold py-1`}
                        >
                            {priceOpt.display_original ? priceOpt.original : "-"}
                        </p>
                    </>
                );
                break;
        }
    };


    const handleAddToCart = () => {
        switch (true) {
            case product?.product_type === "variant" && productVariant?.id:
                switch (true) {
                }
        }
    };

    const renderVariantPrice = (item) => {
        //3127,4248

        let price = item?.price?.price?.original;
        let discount = item?.price?.discount?.amount;
        let rebate_amount = item?.price?.rebate?.amount;

        return (
            <>
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena: <span>{currencyFormat(price)}</span>
                            </Box>

                            {rebate_amount && (
                                <Box>
                                    Cena sa rabatom: <span>{currencyFormat(price - rebate_amount)}</span>
                                </Box>
                            )}

                            {discount > 0 && (
                                <Box>
                                    Cena sa popustom: <span>{currencyFormat(price - discount)}</span>
                                </Box>
                            )}
                        </Box>
                    }
                >
                    <span className={`${scss.price} text-[1rem] self-start font-normal py-2 ${scss.discounted}`}>{currencyFormat(price - discount - rebate_amount)}</span>
                </Tooltip>
            </>
        );
    };
    return (
        <div className={classes.wrapper}>
            {/* Price */}
            <div className={classes.price}>
                {/* Previous price (strikethrough) */}

                {/*{product?.item?.price?.second && (*/}
                {/*    <span className={classes.inactive} onDoubleClick={showRebate(product)}>*/}
                {/*        {currencyFormat(product?.price?.second, product?.price?.currency)}*/}
                {/*    </span>*/}
                {/*)}*/}

                {/* Active price */}
                {productVariant ? (
                    <span className={classes.active}>{renderVariantPrice(productVariant)}</span>
                ) : (
                    <span className={`${classes.active}`}>{renderPrices(product?.item)}</span>
                )}
            </div>

            {/* Add to cart */}
            <div className={classes.cart}>
                <FormWrapper
                    className={`${classes.form}`}
                    onSubmit={() => {
                        if (isPlusMinusInputEmpty) {
                            toast.warn("Molimo unesite količinu!");
                            setLoading(false);
                        } else {
                            const productItem = productVariant ? productVariant : product?.item;
                            api.globalAddToCart(productItem?.basic_data?.id_product, getQuantity(), loading, setLoading);
                            setQuantity(1);
                        }
                    }}
                >
                    {product.item.inventory.inventory_defined && product?.item?.price?.price_defined ? (
                        <PlusMinusInput
                            max={productVariant ? +productVariant?.inventory?.amount : +product?.item?.inventory?.amount}
                            className={classes.input}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            setIsPlusMinusInputEmpty={setIsPlusMinusInputEmpty}
                        />
                    ) : null}

                    <AddToCartButton
                        className={classes.addToCart}
                        short={false}
                        loading={loading}
                        product={product?.item}
                        productVariant={productVariant}
                        onClick={() => {
                            setLoading(true);
                            // productVariant
                            //     ? productVariant?.inventory?.amount > 0
                            //         ? api.globalAddToCart(productVariant?.basic_data?.id_product, getQuantity())
                            //         : null
                            //     : product?.item?.inventory?.amount > 0
                            //     ? api.globalAddToCart(product?.item?.basic_data?.id_product, getQuantity())
                            //     : null;

                            if (product?.item?.product_type === "variant") {
                                if (productVariant?.inventory?.inventory_defined && productVariant?.basic_data?.id_product) {
                                    if (isPlusMinusInputEmpty) {
                                        toast.warn("Molimo unesite količinu!");
                                        setLoading(false);
                                    } else {
                                        api.globalAddToCart(productVariant?.basic_data?.id_product, getQuantity(), loading, setLoading);
                                        setQuantity(1);
                                    }
                                } else {
                                    if (!productVariant?.basic_data?.id_product) {
                                        toast.error("Odaberite varijaciju proizvoda");
                                        setLoading(false);
                                    } else {
                                        if (productVariant?.basic_data?.id_product && (!productVariant?.inventory?.inventory_defined || !productVariant?.price?.price_defined)) {
                                            navigate(`/kontakt?slug=${productVariant?.slug}`, { state: { product: product } });
                                        }
                                    }
                                }
                            } else {
                                if (product?.item?.inventory?.inventory_defined && product?.item?.price?.price_defined) {
                                    if (isPlusMinusInputEmpty) {
                                        toast.warn("Molimo unesite količinu");
                                        setLoading(false);
                                    } else {
                                        api.globalAddToCart(product?.item?.basic_data?.id_product, getQuantity(), loading, setLoading);
                                        setQuantity(1);
                                    }
                                } else navigate(`/kontakt?slug=${product?.item?.slug}`, { state: { product: product } });
                            }
                        }}
                    />
                </FormWrapper>
                <Button
                    className={`${classes.wishlist} ${inWishlist && classes.inWishlist}`}
                    label={<img src={icons.wish_list} alt={icons.wish_list} className={`${classes.heart} `} />}
                    onClick={() => api.globalAddToWishlist(product?.item?.basic_data?.id_product, !inWishlist)}
                />
            </div>
        </div>
    );
};

export default Footer;
